import axios from "axios";
const url = "https://gympods-server.onrender.com";
// const url = 'http://localhost:5001';
// const url = 'https://gympods-dev-server.onrender.com';

/***********  Admin account functions ***********/

// Login a user
async function login(email, password) {
  const data = {
    email,
    password
  };
  // send request to server
  const response = await axios.post(`${url}/login`, data).catch(() => { alert('Incorrect email or password'); });
  if (response.status == 200) {
    // if successful, add session to localStorage
    localStorage.userSession = response.data.token;
    sessionStorage.userSession = response.data.token;
    localStorage.name = response.data.resName;
    localStorage.email = response.data.resEmail;
    if (!response.data.admin) {
      alert("You are not an admin");
      return false;
    }
    localStorage.operator = response.data.operator;
    window.location.href = "/";
  } else {
    alert(`Login failed. ${response.data.message}`);
    return false;
  }
}

/***********  Check in functions ***********/

// Get PODS in a site
// Get the week's bookings for a single Pod by pod_id
async function getPODS(site) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.get(`/admin/pods?site=${site}`)
    .catch(() => window.location.href = "/login");
  console.log(response);
  return response.data.pods.pods;
}

// Get the week's bookings for a single Pod by pod_id
async function getBookings(week, pod) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.get(
    `/admin/bookings?pod=${pod}&week=${week}`
  );
  // console.log(response);
  return response.data.schedule.bookings;
}

// Remove a booking from admin
async function removeBooking(week, pod, booking) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.delete(
    `/admin/booking/${booking}?week=${week}&pod=${pod}`
  );
  console.log(response);
  return response.data.success;

}

// Check in a user from QR code, TODO: QR code
async function checkIn(res) {
  const email = res.split("/")[0];
  const booking = res.split("/")[1];

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(
    `/admin/checkin`,
    {
      email,
      booking
    }
  );
  console.log(response);

  if (response.data.success == true) {
    return response.data.Booking;
  } else return false;
}

async function markAttendance(week, pod, booking, attendance, classpassBookingId) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(
    '/admin/attendance',
    {
      week,
      pod,
      booking,
      attendance,
      classpassBookingId
    }
  )
    .catch(err => {
      alert('Error updating attendance');
      console.log(err);
    });

  if (response.data.success == true) {
    return response.data;
  }
}

// Edit a booking's note
async function addNote(week, pod, booking, note) {
  const newNote = prompt("Enter new note", note || "");

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(
    '/admin/note',
    {
      week,
      pod,
      booking,
      note: newNote
    }
  )
    .catch(err => {
      alert('Error updating note');
      console.log(err);
    });

  console.log(response.data.success);
  if (response.data.success == true) {
    return newNote;
  }
}

/***********  User functions ***********/

// Find user by email
async function findUser(email) {
  if (!email) alert("Please enter a valid email");
  else {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "adminKey": `${localStorage.adminKey}`,
        Authorization: `Bearer ${localStorage.userSession}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    const response = await instance.get(
      `/admin/user/${email}`
    );
    // console.log(response);
    if (response.data.success == true) return response.data.user;
  }
}

// Search for users by name or email
async function searchUsers(query) {
  if (!query) alert("Please enter a valid search term");
  else {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "adminKey": `${localStorage.adminKey}`,
        Authorization: `Bearer ${localStorage.userSession}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    const response = await instance.get(
      `/admin/users/${query}`
    );
    // console.log(response);
    if (response.data.success == true) return response.data.results;
    else return false;
  }
}

// Find a PT account by email
async function findPT(email) {
  if (!email) alert("Please enter a valid email");
  else {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "adminKey": `${localStorage.adminKey}`,
        Authorization: `Bearer ${localStorage.userSession}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    const response = await instance.get(
      `/admin/pt/${email}`
    );
    // console.log(response);
    if (response.data.success == true) return response.data.pt;
    else return false;
  }
}

// Verify a PT account
async function verifyPT(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(`/admin/pt/${id}`);
  return response.data.success;
}

// Add a credit to a user
async function addCredit(id, amount) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const data = {
    id,
    amount
  }
  const response = await instance.post(`/admin/add_credit`, data);
  if (response.data.success) return true;
  else return false;
}

// Cancel a user's subscription
async function cancelSubscription(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(`/admin/cancel_subscription/${id}`);
  return response.data.user;
}

// Activate a user's subscription
async function activateSubscription(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.post(`/admin/activate_subscription/${id}`);
  return response.data.user;
}

/***********  Management functions ***********/

// Get data for analytics page
async function getAnalytics(site, week) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.get(`/admin/analytics/${site}?week=${week}`);
  console.log(response.data)
  return response.data?.data || false;
}

// Add a new closure date/time
async function addClosure(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });

  const response = await instance.post(`/admin/closure`, data);
  return response.data.closure;
}

// Get all closure dates/times
async function getClosures() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  const response = await instance.get(`/admin/closures`);
  return response.data.closures;
}

// Delete a closure date/time
async function deleteClosure(id) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });

  const response = await instance.delete(`/admin/closure/${id}`);
  return response.data.success;
}

// Get all offer
async function getOffers() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.get(`/admin/offers`);
  return response.data.offers;
}

// Add an offer
async function addOffer(data) {
  if (!data) alert("Please enter a valid data");

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.post(`/admin/offer`, data);
  return true;
}

// Delete an offer
async function deleteOffer(site, offer) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.delete(`/admin/offer/${site}/${offer}`);
  return response.data.success;
}

// Get all admins
async function getAdmins() {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.get(`/admin/admins`);
  return response.data.admins;
}

// add an new admin
async function addAdmin(email) {
  if (!email) alert("Please enter a valid email");

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.post(`/admin/admin`, { email });
  return response.data.user;
}

// delete an admin
async function removeAdmin(email) {
  if (!email) alert("Please enter a valid email");

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.delete(`/admin/admin/${email}`);
  return response.data.success;
}

// Toggle an admin's operator privileges
async function toggleOperator(email) {
  if (!email) alert("Please enter a valid email");

  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.put(`/admin/admin/${email}`);
  return response.data.success;
}

async function exportBookings(site, start, end, user) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.post(`/admin/export`, { site, start, end, user });
  if (response.data.success) return response.data.bookings;
  else return false;
};

// Get all PT listings
async function getPTListings() {
  const response = await axios.get(`${url}/pt/listings/all`);
  console.log(response);
  return response.data.listings;
}

// Add a PT listing
async function addPTListing(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.post(`/pt/listing`, data);
  return response.data.success;
}

// Edit a PT listing
async function editPTListing(data) {
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.put(`/pt/listing`, data);
  console.log(response);
  return response.data.success;
}

async function getMessages() {
  const instance = axios.create({
    baseURL: "http://localhost:5001",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.get(`/admin/messages`);
  return response.data.messages;
}

async function addData(message, model) {
  const instance = axios.create({
    baseURL: "http://localhost:5001",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "adminKey": `${localStorage.adminKey}`,
      Authorization: `Bearer ${localStorage.userSession}`,
    },
    withCredentials: true,
    credentials: "same-origin",
  });
  const response = await instance.post(`/admin/data`, { message, model});
  return response.data.success;
}

export {
  login,
  getPODS,
  getBookings,
  removeBooking,
  checkIn,
  markAttendance,
  addNote,
  findUser,
  searchUsers,
  findPT,
  verifyPT,
  addCredit,
  cancelSubscription,
  activateSubscription,
  getAnalytics,
  addClosure,
  getClosures,
  deleteClosure,
  getOffers,
  addOffer,
  deleteOffer,
  getAdmins,
  addAdmin,
  removeAdmin,
  toggleOperator,
  exportBookings,
  getPTListings,
  addPTListing,
  editPTListing,
  getMessages,
  addData
};
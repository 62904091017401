<template>
  <div v-if="display" class="booking">
    <h4 class="time">{{ time }}</h4>
    <h4>{{ booking.user.name }}</h4>
    <a @click="() => this.showUser = !this.showUser">{{ booking.user.email }}</a>
    <p>{{ type }}</p>
    <i>
      <i>{{ noteText ? noteText : '_' }}</i>
      <i @click="addBookingNote" class="fas fa-pencil"></i>
    </i>
    <section>
      <i @click="markAttendenceFalse" class="fas fa-times" :class="attended === false && 'bgRed'"></i>
      <i v-if="operator" @click="removeBooking" class="fas fa-trash"></i>
      <i @click="markAttendenceTrue" class="fas fa-check" :class="attended === true && 'bgGreen'"></i>
    </section>
    <br />
  </div>

  <UserModal v-if="showUser" :id="booking.user.id" :email="booking.user.email" :close="closeUserModal" />
</template>

<script>
import UserModal from '@/components/UserModal.vue';
import { addNote, markAttendance, removeBooking } from '@/app/routes';

export default {
  name: 'Booking',
  components: {
    UserModal,
  },
  props: {
    pod: Object,
    booking: Object,
    week: Number,
  },
  data() {
    return {
      time: '',
      type: '',
      noteText: '',
      attended: null,
      showUser: false,
      operator: localStorage.operator == 'true',
      display: true,
    }
  },
  methods: {
    async addBookingNote() {
      const newNote = await addNote(this.week, this.pod._id, this.booking._id, this.noteText);
      this.noteText = newNote;
      // update in session storage
      let bookings = JSON.parse(sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`]);
      bookings[new Date(this.booking.start).getUTCDay()].forEach((booking) => {
        if (booking._id === this.booking._id) booking.note = this.noteText;
      });
      sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`] = JSON.stringify(bookings);
    },
    markAttendenceTrue() {
      markAttendance(this.week, this.pod._id, this.booking._id, true, this.booking.appointment_id);
      this.attended = true;
      // update in session storage
      let bookings = JSON.parse(sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`]);
      bookings[new Date(this.booking.start).getUTCDay()].forEach((booking) => {
        if (booking._id === this.booking._id) booking.attended = true;
      });
      sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`] = JSON.stringify(bookings);
    },
    markAttendenceFalse() {
      markAttendance(this.week, this.pod._id, this.booking._id, false, this.booking.appointment_id);
      this.attended = false;
      // update in session storage
      let bookings = JSON.parse(sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`]);
      bookings[new Date(this.booking.start).getUTCDay()].forEach((booking) => {
        if (booking._id === this.booking._id) booking.attended = false;
      });
      sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`] = JSON.stringify(bookings);
    },
    async removeBooking() {
      const confirm = window.confirm('Are you sure you want to remove this booking? The user will not be refunded!');
      if (!confirm) return;
      const response = await removeBooking(this.week, this.pod._id, this.booking._id);
      if (response) {
        // update in session storage
        let bookings = JSON.parse(sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`]);
        bookings[new Date(this.booking.start).getUTCDay()] = bookings[new Date(this.booking.start).getUTCDay()].filter((booking) => booking._id !== this.booking._id);
        sessionStorage[`bookings-${this.pod._id.toString()}-${this.week.toString()}`] = JSON.stringify(bookings);
        this.display = false;
      }
    },
    closeUserModal() {
      this.showUser = false;
    }
  },
  mounted() {
    // Format the time
    let startDate = new Date(this.booking.start);
    startDate = startDate.toString();
    let endDate = new Date(this.booking.end);
    endDate = endDate.toString();
    this.time =
      startDate.substring(16, 21) + " - " + endDate.substring(16, 21);

    // Set the type
    if (this.booking.user.single_booking === true) this.type = "single";
    else if (this.booking.user.isPT === true) this.type = "PT";
    else this.type = "buddy";

    // Set the attended
    this.attended = this.booking.attended;

    // Set the note
    this.noteText = this.booking.note;
  },
}
</script>

<style scoped lang="scss">
.booking {
  cursor: default;
  width: 250px;
  padding: unset;
  border-radius: unset;
  border-bottom: solid 2px #7fc0c3  ;

  &:last-child {
    border-bottom: none;
  }

  h4 {
    padding: 0 5px;
  }

  .time {
    text-align: center;
    font-size: 1.1em;
  }

  a {
    display: inline-block;
    padding: 5px;
    overflow-x: auto;
    max-width: 100%;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      border-radius: 5px;
      z-index: 10;
      background-color: #69696b;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #999999;
    }
  }

  p {
    padding: 0 5px;
  }

  .fa-pencil {
    padding-left: 5px;
    float: right;
    cursor: pointer;
  }

  .removeBtn {
    width: 80%;
    padding: 3px;
    border: solid 2px #EFAB7D;
    background: none;
    color: white;
    border-radius: 3px;
    margin: 10px 10%;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #EFAB7D;
    }
  }

  &:hover {
    background: none;
  }

  &:hover a:hover {
    background: #EFAB7D;
    border-radius: 3px;
    cursor: pointer;
  }

  section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;

    i {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 95px;
      padding: 5px;
      background: #3d3d3d;
      cursor: pointer;
      transition: 0.2s;
    }

    .fa-check {
      border-radius: 0px 10px 10px 0px;

      &:hover {
        background: rgb(0, 104, 0);
      }
    }

    .fa-times {
      border-radius: 10px 0px 0px 10px;
      margin-right: 2px;

      &:hover {
        background: rgb(180, 0, 0);
      }
    }

    .fa-trash {
      margin-right: 2px;

      &:hover {
        background: #EFAB7D;
      }
    }

    .bgRed {
      background: rgb(180, 0, 0);
    }

    .bgGreen {
      background: rgb(0, 104, 0);
    }
  }
}
</style>
<template>
  <nav id="nav" v-if="nav"  :class="showNav && 'navEnlarge'">
    <i class="fa" :class="showNav ? 'fa-chevron-left' : 'fa-chevron-right'" id="openNav" @click="toggleAdminNav"></i>
    <router-link to="/"><img src="@/assets/logo.png" alt="logo" /><span>Bookings</span></router-link>
    <router-link to="/analytics"><i class="fa fa-chart-line"></i><span>Analytics</span></router-link>
    <router-link to="/checkin"><i class="fa fa-qrcode"></i><span>Check In</span></router-link>
    <router-link to="/users"><i class="fa fa-users"></i><span>Users</span></router-link>
    <router-link v-if="operator" to="/times"><i class="fa fa-calendar-week"></i><span>Opening Times</span></router-link>
    <router-link v-if="operator" to="/admins"><i class="fa-solid fa-unlock"></i><span>Manage Admins</span></router-link>
    <router-link v-if="operator" to="/offers"><i class="fa fa-pound-sign"></i><span>Offers</span></router-link>
    <router-link v-if="operator" to="/pt-listings"><i class="fa fa-dumbbell"></i><span>PT Listings</span></router-link>
    <a v-if="authed" @click="logout" id="logout"><i class="fa-solid fa-right-from-bracket"></i><span>Logout</span></a>
  </nav>
  <router-view class="router-view" :class="showNav && 'navEnlarge'" />
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      authed: false,
      operator: false,
      nav: true,
      settings: false,
      isSettingsRoute: false,
      showNav: false
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('userSession');
      localStorage.removeItem('operator');
      this.$router.push('/login');
      this.authed = false;
      this.operator = false;
    },
    toggleAdminNav() {
      this.showNav = !this.showNav;
    },
  },
  mounted() {
    sessionStorage.clear();
    if (localStorage.getItem('userSession')) {
      this.authed = true;
      if (localStorage.operator == 'true') {
        this.operator = true;
      }
    } else {
      this.$router.push('/login');
    }

    if (window.innerWidth < 600) {
      this.nav = false;
    }
  }
}
</script>


<style lang="scss">
html {
  background-color: #272a2c;
}

.router-view,
#app {
  position: fixed;
  top: 0;
  right: 00px;
  width: calc(100% - 90px);
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: small;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: #272a2c;
  transition: width 0.2s;

  &.navEnlarge {
    width: calc(100% - 200px);
  }

  h1 {
    color: #7fc0c3;
  }
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: calc(100% - 50px);
  padding: 10px 15px;
  margin: 16px 10px;
  border-radius: 5px;
  background-color: #1b1e1f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;

  a {
    font-weight: bold;
    text-decoration: none;
    color: white;
    display: block;
    width: 50px;
    height: 50px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transition: background-color 0.1s ease-in-out, width 0.2s ease-in-out;
    font-size: 20px;

    &.router-link-exact-active {
      background-color: #58595B;
      // color: #7fc0c3;
    }

    img {
      width: 30px;
    }

    span {
      visibility: hidden;
      width: 0;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }
  }

  #openNav {
    position: absolute;
    top: 10px;
    left: 20px;
    padding: 15px;
    font-size: medium;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      color: #7fc0c3;
    }
  }

  &.navEnlarge {
    width: 160px;

    a {
      width: 160px;
      text-align: left;
      justify-content: flex-start;
      padding-left: 5px;

      i {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        margin: 0 6px;
      }

      span {
        visibility: visible;
        width: auto;
        opacity: 1;
        font-weight: normal;
        font-size: small;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }

  #logout {
    cursor: pointer;
  }
}

#settings {
  position: fixed;
  top: calc(50vh - 30px);
  right: calc(50vw - 105px);
  z-index: 100;
  background: #2b2627;
  display: flex;
  border-radius: 5px;

  a {
    font-weight: bold;
    text-decoration: none;
    color: white;
    display: block;
    width: 70px;
    height: 70px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    transition: background-color 0.1s ease-in-out;
    font-size: 25px;

    &.router-link-exact-active {
      background-color: #58595B;
    }
  }
}

#settingsTint {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - 90px);
  height: 100vh;
  background: black;
  opacity: 0.5;
  z-index: 99;
}

@media (max-width: 600px) {
  .router-view, #app {
    left: 0;
    width: 100%;
  }
}
</style>

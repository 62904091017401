import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AnalyticsView.vue')
  },
  {
    path: '/checkin',
    name: 'checkin',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CheckinView.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/UsersView.vue')
  },
  {
    path: '/times',
    name: 'times',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/TimesView.vue')
  },
  {
    path: '/admins',
    name: 'admins',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminsView.vue')
  },
  {
    path: '/offers',
    name: 'offers',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "offers" */ '../views/OffersView.vue')
  },
  {
    path: '/pt-listings',
    name: 'pt-listings',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "pt-listings" */ '../views/PTListingsView.vue')
  },
  {
    path: '/data-harvest',
    name: 'data-harvest',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "data-harvest" */ '../views/DataHarvestView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.userSession) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router

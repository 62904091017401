<template>
  <div class="home">
    <div id="header">
      <h1 id="left" v-if="width > 600">GYMPODS Bookings</h1>

      <span id="right">
        <div id="weekSelect">
          <a @click='backDay' class="fas fa-chevron-left"></a>
          <b v-if="width > 600">{{ this.date }}</b>
          <b v-else>{{ this.date.substring(0, 5) }}</b>
          <a @click='addDay' class="fas fa-chevron-right"></a>
        </div>
        <select v-model="site">
          <option style="color: black" value='DALSTON'>DALSTON</option>
        </select>
        <a v-if="operator && width > 600" @click="this.exportModal = true" class="fa fa-download"></a>
      </span>
    </div>

    <Bookings :site="site" :day="day" :week="week" />

    <Export v-if="exportModal" :close="closeExportModal" />
  </div>
</template>

<script>
import Bookings from '@/components/Bookings.vue'
import Export from '@/components/Export.vue'

export default {
  name: 'HomeView',
  components: {
    Bookings,
    Export
  },
  data() {
    return {
      site: 'DALSTON',
      day: 0,
      week: 0,
      date: '',
      exportModal: false,
      operator: false,
      width: window.innerWidth
    }
  },
  methods: {
    addDay() {
      // add 86400000 to this.day to trigger reactivity
      if (new Date(this.day).getUTCDay() == 6) {
        this.week += 604800000;
      }
      const newDay = this.day + 86400000;
      this.day = newDay;
      const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];
      this.date = new Date(this.day).getUTCDate().toString() + '/' + months[new Date(this.day).getUTCMonth()] + '/' + new Date(this.day).getUTCFullYear()
    },
    backDay() {
      if (new Date(this.day).getUTCDay() == 0) {
        this.week -= 604800000;
      }
      this.day -= 86400000
      const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];
      this.date = new Date(this.day).getUTCDate().toString() + '/' + months[new Date(this.day).getUTCMonth()] + '/' + new Date(this.day).getUTCFullYear()
    },
    closeExportModal() {
      this.exportModal = false;
    }
  },
  mounted() {
    if (localStorage.operator == 'true') this.operator = true;
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12"
    ];
    this.date = new Date().getUTCDate().toString() + '/' + months[new Date().getUTCMonth()] + '/' + new Date().getUTCFullYear();

    let newDateFunction = new Date();
    const dateCopy = new Date(newDateFunction.getTime());
    const nextSunday = new Date(
      dateCopy.setDate(dateCopy.getDate() - ((7 + dateCopy.getDay()) % 7))
    );
    const lastSun = nextSunday.toString().substring(0, 15) + " GMT+0000";
    const d = new Date(lastSun);
    const week = Date.parse(d);
    this.week = week;
    this.day = week + 86400000 * newDateFunction.getDay();

    document.addEventListener('keydown', event => {
      if (event.keyCode == 37) {
        this.backDay()
      } else if (event.keyCode == 39) {
        this.addDay()
      }
    })
  }
}
</script>

<style scoped lang="scss">
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;

  #left {
    margin: 10px;
    font-weight: normal;
    font-size: 1.8em;
  }

  #right {
    display: flex;
    align-items: center;

    .fa-download {
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    select {
      background: none;
      color: white;
      padding: 10px;
      border: solid 1px white;
      width: 20vw;
      max-width: 200px;
      margin: 0;
      border-radius: 5px;
      height: 42px;
      box-sizing: border-box;
      margin: 0 20px;
    }

    #weekSelect {
      width: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid 1px white;
      border-radius: 5px;

      a {
        padding: 10px 15px;
        box-sizing: border-box;
        height: 40px;
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          background: #58595B;
        }

        &:first-child {
          border-right: solid 1px white;
        }

        &:last-child {
          border-left: solid 1px white;
        }
      }

      b {
        box-sizing: border-box;
        padding: 10px 35px;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    margin-bottom: 0;
  }

  #header {
    margin-top: 10px;

    #left {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      select {
        width: 30vw;
        cursor: pointer;
      }

      a {
        display: block;
        margin-right: 10px;
        font-size: 18px;
      }
    }

    #right {
      width: 140vw;
      height: 38px;

      b {
        padding: 10px 5px;
      }
    }
  }
}
</style>
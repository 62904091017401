<template>
  <div id="PODS">
    <div v-for="(pod, index) in pods" :key="pod.id" class="POD" :class="index % 2 == 0 ? 'odd' : 'even'">
      <h3>
        {{ pod.type }}
        <span style="font-size: 12px; margin-left: 10px">({{ getPodBookingsLength(index) }})</span>
      </h3>

      <Booking v-for="booking in getPodBookings(index)" :key="booking._id" :pod="pod" :booking="booking" :week="week" />
    </div>
  </div>
</template>

<script>
import Booking from '@/components/Booking.vue';
import { getPODS, getBookings } from '@/app/routes';

export default {
  name: 'Bookings',
  components: {
    Booking
  },
  props: {
    site: String,
    day: Number,
    week: Number
  },
  data() {
    return {
      pods: null,
      bookings: []
    };
  },
  mounted() {
    this.fetchData();
    this.setHeights();
  },
  watch: {
    day(newDay) {
      this.fetchData();
      this.setHeights();
    }
  },
  methods: {
    fetchData() {
      getPODS(this.site, this.day).then((response) => {
        const fetchBookings = response.map((pod) => {
          return new Promise((resolve) => {
            let bookings = [[], [], [], [], [], [], []];

            if (
              sessionStorage[
              `bookings-${pod._id.toString()}-${this.week.toString()}`
              ]
            ) {
              console.log('found bookings in sessionStorage');
              bookings = JSON.parse(
                sessionStorage[
                `bookings-${pod._id.toString()}-${this.week.toString()}`
                ]
              );
              resolve(bookings[new Date(this.day).getUTCDay()]);
            } else {
              getBookings(this.week, pod._id).then((res) => {
                let foundBookings = res;

                // sort bookings by start time
                foundBookings = foundBookings.sort((a, b) => {
                  return a.start - b.start;
                });

                // filter bookings by day
                for (const booking of foundBookings) {
                  const date = new Date(booking.start);
                  const day_ = date.getUTCDay();
                  bookings[day_].push(booking);
                }

                // save bookings to sessionStorage
                sessionStorage[
                  `bookings-${pod._id.toString()}-${this.week.toString()}`
                ] = JSON.stringify(bookings);
                resolve(bookings[new Date(this.day).getUTCDay()]);
              });
            }
          });
        });

        Promise.all(fetchBookings).then((results) => {
          this.bookings = results;
          this.pods = response;
        });
      });
    },
    setHeights() {
      setTimeout(() => {
        const pods = document.getElementsByClassName("POD");
        const maxHeight = Math.max(
          ...Array.from(pods).map((pod) => pod.scrollHeight)
        );
        for (const pod of pods) {
          pod.style.height = maxHeight + "px";
        }
      }, 1000);
    }
  },
  computed: {
    getPodBookings() {
      return (index) => this.bookings[index];
    },
    getPodBookingsLength() {
      return (index) => this.bookings[index].length;
    }
  }
};
</script>


<style scoped lang="scss">
#PODS {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  border-radius: 5px 5px 0 0;
  margin: 0px 20px;
  margin-bottom: 20px;
  background: #34383b;

  &::-webkit-scrollbar {
    width: 5px;
    height: 15px;
    border-radius: 0 0 5px 5px;
    z-index: 10;
    background-color: #69696b;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0 0 5px 5px;
    background-color: #7fc0c3;
  }

  .even, .odd {
    border-right: solid 2px #73787c;
    background: #34383b;
    
    h3 {
      border-bottom: solid 2px #73787c;
      position: sticky;
      padding-top: 5px;
      top: 0;
      background-color: inherit;
    }
  }

  .even {
    background: #494c4f;
  }

  .POD {
    min-height: 100%;
    min-width: 250px;
    text-align: left;
    padding: 0px 20px;
    height: fit-content;

    h3 {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.1px;
      font-size: 1em;

      span {
        font-weight: normal;
        margin-left: 20px;
        font-size: inherit;
      }
    }

    .time {
      text-align: center;
      font-size: 1.1em;
    }

    div {
      margin-left: 0px;
    }
  }
}
</style>